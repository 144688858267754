
import { defineComponent, reactive } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import { DataItem } from '@/types/app.global';
import RestTable from '@/components/table/RestTable.vue';

export default defineComponent({
  name: 'SimCardProfit',
  components: { RestTable },
  setup() {
    const { https, mapper } = useInject();
    const { userMeta } = useGetters();

    const tableOpts = reactive({
      pageable: false,
      search: {
        criteria: {
          cols: [
            {
              field: 'uid',
              label: '代理',
              type: 'agent',
              required: true
            }
          ]
        },
        remote: {
          action: https?.market.listSimCardProfit
        }
      },
      cols: [
        {
          field: 'simCardTypeCode',
          label: '流量卡编码'
        },
        {
          field: 'simCardTypeName',
          label: '流量卡名称'
        },
        {
          field: 'backerNo',
          label: '品牌',
          options: mapper?.device.backerNo
        },
        {
          field: 'profit',
          label: '返现金额'
        },
        {
          field: 'alias',
          label: '用户名'
        },
        {
          field: 'phone',
          label: '手机号'
        },
      ],
      schema: {
        content: {
          cols: [
            {
              field: 'alias',
              label: '用户名',
              type: 'agent',
              disabled: ['edit'],
              required: true,
              evaluate: (data: DataItem) => ({ uid: data.uid, alias: data.alias }),
            },
            {
              field: 'simCardTypeName',
              label: '流量卡',
              mapper: ['code', 'name'],
              type: 'select',
              remote: {
                action: https?.market.listSimCardType
              },
              evaluate: (data: any) => ({ simCardTypeCode: data.code, simCardTypeName: data.name }),
              disabled: ['edit'],
              required: true
            },
            {
              field: 'profit',
              label: '返现金额',
              type: 'number',
              scale: 2,
              required: true
            },
            {
              field: 'simCardTypeName',
              visible: false,
            },
            {
              field: 'uid',
              visible: false,
            }
          ],
          evaluate: (data: DataItem) => {
            const list = [{ simCardTypeCode: data.simCardTypeCode, profit: data.profit }];
            return { submitterUid: userMeta.uid, submitterAlias: userMeta.alias, submitterPhone: userMeta.phone, submitterName: userMeta.name,
              targetUid: data.uid, profitList: JSON.stringify(list) };
          }
        }
      },
      builtinCmd: {
        create: { action: https?.market.editSimCardProfit },
        update: { action: https?.market.editSimCardProfit },
      }
    });
    return { tableOpts };
  }
});
