import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"inherit","display":"flex","flex-direction":"column"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_search_table = _resolveComponent("search-table")!
  const _component_form_modal = _resolveComponent("form-modal")!
  const _directive_perm = _resolveDirective("perm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_search_table, {
      ref: "table",
      opts: _ctx.tableOpts
    }, {
      button: _withCtx(() => [
        (_ctx.tableOpts.builtinCmd.create)
          ? _withDirectives((_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              onClick: _ctx.expose.onCreate
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_plus_outlined)
              ]),
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.tableOpts.builtinCmd.create.label || '新建'), 1)
              ]),
              _: 1
            }, 8, ["onClick"])), [
              [_directive_perm, void 0, "create"]
            ])
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "button", { expose: _ctx.expose })
      ]),
      _: 3
    }, 8, ["opts"]),
    (_ctx.tableOpts.builtinCmd?.create)
      ? (_openBlock(), _createBlock(_component_form_modal, {
          key: 0,
          opts: _ctx.createOpts
        }, null, 8, ["opts"]))
      : _createCommentVNode("", true),
    (_ctx.tableOpts.builtinCmd?.update)
      ? (_openBlock(), _createBlock(_component_form_modal, {
          key: 1,
          opts: _ctx.updateOpts
        }, null, 8, ["opts"]))
      : _createCommentVNode("", true)
  ]))
}