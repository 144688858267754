
import { defineComponent, onMounted, PropType, reactive, ref } from 'vue';
import { cloneDeep } from 'lodash';
import { CommandOptions, RestTableOptions } from '@/types/table.config';
import { SearchTableExposeType } from '@/types/types.config';
import { FormModalType } from '@/types/types.global';
import { DataItem } from '@/types/app.global';
import { useInject } from '@/utils/inject';

import SearchTable from '@/components/table/SearchTable.vue';
import FormModal from '@/components/composite/FormModal.vue';
import { PlusOutlined } from '@ant-design/icons-vue';

/**
 * 带有增删改等功能的表格，基本符合Restful规范
 */
export default defineComponent({
  name: 'RestTable',
  components: { FormModal, SearchTable, PlusOutlined },
  props: {
    opts: {
      type: Object as PropType<RestTableOptions>,
      default: () => ({})
    }
  },
  setup(props) {
    const table = ref<SearchTableExposeType>();
    const tableOpts = reactive(props.opts);
    const { notify, message } = useInject();

    let criteriaRequired = false;
    // 检查查询条件是否有必填项，如果有，增删改的操作完成后不能刷新数据，否则会报错
    tableOpts.search?.criteria?.cols?.forEach(col => {
      if (col.required === true || col.choice === true) {
        criteriaRequired = true;
      }
    });

    let createOpts = {} as FormModalType;
    let updateOpts = {} as FormModalType;

    const onCreate = () => {
      createOpts.visible = true;
    };

    const doCreate = (data: DataItem) => {
      tableOpts.builtinCmd?.create?.action(data).then(res => {
        createOpts.loading = false;
        if (res.respType === 'OK') {
          createOpts.visible = false;
          message?.success('新增成功');

          // 如果查询条件有必填项，不刷新数据，否则会报错
          if (!criteriaRequired) {
            table.value?.expose.refresh();
          }
        } else {
          notify?.error(res.respMesg, '新增失败');
        }
      }, () => { createOpts.loading = false; });
    };

    const onUpdate = (data: DataItem) => {
      updateOpts.content.entity = data;
      updateOpts.visible = true;
    };

    const doUpdate = (data: DataItem) => {
      tableOpts.builtinCmd?.update?.action(data).then(res => {
        updateOpts.loading = false;

        if (res.respType === 'OK') {
          updateOpts.visible = false;
          message?.success('修改成功');

          table.value?.expose.replace(data);
        } else {
          notify?.error(res.respMesg, '修改失败');
        }
      }, () => { updateOpts.loading = false; });
    };

    const doDelete = (data: DataItem) => {
      tableOpts.loading = true;
      tableOpts.builtinCmd?.delete?.action(data).then(res => {
        tableOpts.loading = false;
        if (res.respType === 'OK') {
          table.value?.expose.remove(data);
          message?.success('删除成功');
        } else {
          notify?.error(res.respMesg, '删除失败');
        }
      }, () => { tableOpts.loading = false; });
    };

    createOpts = reactive(cloneDeep(tableOpts.schema));
    createOpts.title = '新建';
    createOpts.visible = false;
    createOpts.loading = false;
    createOpts.action = doCreate;
    createOpts.content.name = 'create';

    updateOpts = reactive(cloneDeep(tableOpts.schema));
    updateOpts.title = '编辑';
    updateOpts.visible = false;
    updateOpts.loading = false;
    updateOpts.action = doUpdate;
    updateOpts.content.name = 'edit';

    // 处理编辑、删除表格内命令，放在其他自定义命令之前
    const commands = [] as CommandOptions[];
    const d = () => false;
    if (tableOpts.builtinCmd?.update) {
      commands.push({ label: '编辑', perm: 'update', action: onUpdate, disabled: tableOpts.builtinCmd?.update.disabled || d });
    }

    if (tableOpts.builtinCmd?.delete) {
      commands.push({ label: '删除', perm: 'delete', action: doDelete, disabled: tableOpts.builtinCmd?.delete.disabled || d, confirm: true });
    }

    if (tableOpts.commands) {
      commands.push(...tableOpts.commands);
    }
    tableOpts.commands = commands;

    const expose = { onCreate, onUpdate } as DataItem;
    onMounted(() => {
      if (table.value?.expose) {
        for (const [k, v] of Object.entries(table.value.expose)) {
          expose[k] = v;
        }
      }
    });
    return { table, tableOpts, createOpts, updateOpts, expose };
  }
});
